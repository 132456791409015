import { gql } from "@apollo/client";

export const GET_MID_AM_INFO = gql`
  query GetMidAM {
    pages(where: {title: "2024 Mid Am Information"}) {
      edges {
        node {
          seo {
            title
            focuskw
            metaDesc
          }
        }
      }
    }
  }
`;

export default GET_MID_AM_INFO;