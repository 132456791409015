import React, { useEffect, useState } from "react";
import { useQuery } from '@apollo/client';
import SEO from '../components/seo/seo';
import {graphql, StaticQuery, Link} from 'gatsby';
import Loading from '../components/loading/loading';
import MidAMInfoStyle from '../components/styled/mid-am-info';
import GET_MID_AM_INFO from '../graphql/get-mid-am-info';
import GET_MID_AM from '../graphql/get-mid-am';
// import Background from '../images/footer-pattern-sm.png';

export default function Page() {
  // const [ midAMInfoPage, setMidAMInfoPage ] = useState();
  const [ midAMPage, setMidAMPage ] = useState();
  const [ seo, setSEO ] = useState();
  const { loading, data: pageData } = useQuery(GET_MID_AM_INFO, {
    fetchPolicy: 'no-cache',
  });
  const { loading1, data: pageData1 } = useQuery(GET_MID_AM, {
    fetchPolicy: 'no-cache',
  });
  const [click, setClick] = useState(false);
  const closeMobileMenu = () => setClick(false);

  useEffect(() => {
    if (!loading && pageData && !loading1 && pageData1) {
      // setMidAMInfoPage(pageData.pages.nodes[0]);
      setMidAMPage(pageData1.pages.nodes[0]);
      setSEO(pageData.pages.edges[0].node.seo);
    }
  }, [loading, pageData, loading1, pageData1, midAMPage]);

  return (
    <>
      {loading ? (
        <>
          <Loading />
        </>
      ) :(
        <>
          <SEO
            title={seo?.title || 'Kinloch Golf Club'}
            description={seo?.description}
          />
          <MidAMInfoStyle>
            <div style={{backgroundColor: `rgba(18, 40, 76)`, paddingBottom: `170px`, marginTop: `-170px`}}>
              <div className="background">
                <div className="blue-overlay">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 page-title">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="notes mid-am-info">
                Please click on the following links for more information:
              </div>
              <div className="row mid-am-section mid-am-info-section">
                <StaticQuery 
                  query={graphql`
                  {
                    allWpMenu(filter: {
                      name: {
                        eq: "Info Page Menu"
                        }
                    }) {
                      edges {
                        node {
                          name
                          menuItems {
                            nodes {
                              label
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                `} render={data => (
                    <div className="nav-mid-am-info" id="nav-mid-am-info">
                      <ul className={click ? "nav-options mid-am-info active" : "nav-options mid-am-info"}>
                        {data?.allWpMenu?.edges[0]?.node.menuItems.nodes.map(node => (
                          <li className="option mid-am-info" key={node.label} id={node.label}>
                            <Link 
                              to={`${node.url}`} 
                              target="blank" 
                              onClick={closeMobileMenu} 
                              aria-label={`Go to ${node.label}`}>
                              {node.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                )}/>

                <div className="row desktop-only">
                  <div className="col-md-12 champion-video">
                    <a className="video-link" href={midAMPage?.mid_am_acf?.championVideoLink} target="_blank" rel="noreferrer"><h3>{midAMPage?.mid_am_acf?.championVideoTitle}</h3></a>
                  </div>
                  <div className="col-md-6 mb-6">
                    <h3>Kinloch Golf Club</h3>
                    <a className="video-link" href={midAMPage?.mid_am_acf?.videoLink} target="_blank" rel="noreferrer">A Video Review of Kinloch</a>
                    <a href={midAMPage?.mid_am_acf?.image1Link} target="_blank" rel="noreferrer">
                      <img
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      src={midAMPage?.mid_am_acf?.clubImage1.sourceUrl} 
                      alt={midAMPage?.mid_am_acf?.clubImage1.altText} 
                    /></a>
                  </div>
                  <div className="col-md-6 mb-6">
                    <h3>Independence Golf Club</h3>
                    <a href={midAMPage?.mid_am_acf?.image2Link} target="_blank" rel="noreferrer">
                      <img className="image2"
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      src={midAMPage?.mid_am_acf?.clubImage2.sourceUrl} 
                      alt={midAMPage?.mid_am_acf?.clubImage2.altText} 
                    /></a>
                  </div>
                </div>
              </div>
            </div>
          </MidAMInfoStyle>
        </>
      )}
    </>
  )
}