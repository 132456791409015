import styled from 'styled-components'

const MidAMInfoStyle = styled.div`

  .notes.mid-am-info {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    color: #fff;
    font-family: "Copperplate", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-family: var(--Copperplate);
    text-decoration: none;
    font-size: 1.7rem;
    padding-left: 40px;
    padding-right: 40px;
    @media (max-width: 576px) {
      font-size: 1.4rem;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .background {
    padding: inherit;
  }

  .blue-overlay {
    display: -webkit-flex; /* Safari */
    display:flex;
    /* background-color: rgb(18,40,76, .46); */
    height: 100%;
    align-items: center;
    padding: 6rem 0 3rem;

    .page-title {
      width: 100%;
      text-align: center;
      padding: 8rem 0 2rem;

      h1 {
        font-family: 'Copperplate', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-family: var(--Copperplate);
        color: white;
      }

    } 

  }

  .mid-am-info-section {
    /* padding: 2.5rem 2.5rem; */
    margin-left: auto;
    margin-right: auto;
    /* padding-bottom: 8rem; */
    height: 100vh;
    text-align: center;
    @media (max-width: 576px) {
      display: initial;
      .col-md-6 {
        padding-top: 10px;
      }
      .desktop-only {
        display: none;
      }
    }
  }

  .mid-am-section {
    padding: 2.5rem 2.5rem;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 5rem;
    text-align: center;
    .champion-video {
      margin-top: 40px;
      margin-bottom: 10px;
    }
    h3 {
      text-align: center;
      color: #EDAA1F;
    }
    a {
      text-decoration: none;
      &.bottom-left {
        position: absolute;
        bottom: -40px;
        left: 16px;
      }
      &.bottom-right {
        position: absolute;
        bottom: -40px;
        right: 16px;
      }
      h2 {
        color: #EDAA1F;
      }
      &.video-link {
        color: #fff;
      }
      & img.image2 {
        margin-top: 22px;
      }
    }
    @media (max-width: 576px) {
      display: initial;
      .col-md-6 {
        padding-top: 10px;
      }
    }
  }

`;

export default MidAMInfoStyle;