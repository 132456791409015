import { gql } from "@apollo/client";

export const GET_MID_AM = gql`
  query GetMidAM {
    pages(where: {title: "2024 Mid Am"}) {
      nodes {
        mid_am_acf {
          logo {
            sourceUrl
            altText
          }
          cta
          clubImage1 {
            sourceUrl
            altText
          }
          image1Link,
          videoLink
          courseTourLink1
          clubHistoryLink1
          clubImage2 {
            sourceUrl
            altText
          }
          image2Link
          clubHistoryLink2
          courseTourLink2
          period,
          championVideoTitle,
          championVideoLink
        }
      },
      edges {
        node {
          seo {
            title
            focuskw
            metaDesc
          }
        }
      }
    }
  }
`;

export default GET_MID_AM;